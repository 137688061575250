import {Query} from '@directus/types'
import {ProductFeedbackTypeEntity} from './useFeedbackType'

export const useFeedBacks = (settingsData?: any) => {
  const {getItems} = useDirectusItems()
  const settings: any = settingsData || inject('globalSettings')
  const {generateFeedbackDTO} = useFeedbackUtils(settings)

  const getFeedbacksByProductId = (product_id: string | number, languageCode: string) => {
    return getFeedbacksByQuery(languageCode, {
      fields: ['id', 'product', 'show_mobile_app', 'sort', 'image.id', 'image.title', 'image.description', 'translations.*'],
      filter: {
        product: {
          _eq: product_id
        },
        status: {
          _eq: 'published'
        }
      },

      sort: ['sort'],
      limit: 10,
      page: 1
    })
  }

  const getFeedbacksByProductSlug = (slug: string | number, languageCode: string) => {
    return getFeedbacksByQuery(languageCode, {
      fields: ['id', 'product', 'show_mobile_app', 'sort', 'image.id', 'image.title', 'image.description', 'translations.*', 'product.*', 'product.translations.*'],
      filter: {
        _and: [
          {
            status: {
              _eq: 'published'
            }
          },
          {
            product: {
              translations: {
                languages_code: {
                  _eq: languageCode
                }
              }
            }
          },
          {
            product: {
              translations: {
                slug: {
                  _eq: slug
                }
              }
            }
          }
        ]
      },
      sort: ['sort'],
      limit: 10,
      page: 1
    })
  }


  const getFeedbacksInMobileApp = (languageCode: string) => {
    return getFeedbacksByQuery(languageCode, {
      fields: ['id', 'product', 'show_mobile_app', 'sort', 'image.id', 'image.title', 'image.description', 'translations.*'],
      filter: {
        show_mobile_app: {
          _eq: true
        },
        status: {
          _eq: 'published'
        }
      },
      sort: ['sort'],
      limit: 10,
      page: 1
    })
  }

  const getFeedbacksByQuery = (languageCode: string, query?: Query) => {
    return getProductFeedbacks(query).then((feedbacks) => {
      if (feedbacks && Array.isArray(feedbacks) && feedbacks.length > 0) {
        return feedbacks.map((item) => generateFeedbackDTO(item, languageCode))
      } else return []
    })
  }

  const getProductFeedbacks = async (query?: Query): Promise<Array<ProductFeedbackTypeEntity>> => {
    let data: any = {
      collection: 'feedbacks',
      params: {
        fields: ['id', 'show_mobile_app', 'sort', 'image.id', 'image.title', 'image.description', 'translations.*', 'product.*', 'product.translations.*'],
        filter: {
          status: {
            _eq: 'published'
          }
        },
        limit: -1
      }
    }

    if (query) {
      data.params = {
        ...data.params,
        ...query
      }
    }
    return getItems(data)
  }

  return {getFeedbacksByQuery, getFeedbacksByProductId, getFeedbacksInMobileApp, getFeedbacksByProductSlug}
}
