import {Query} from '@directus/types'
import {consola} from 'consola'
import {TypeIntroductionEntity, TypeIntroductionDto} from "./introduction.type"
import {useIntroductionUtils} from "./useIntroductionUtils"

export const useIntroduction = () => {

  const globalSettings = inject("globalSettings");
  const {getItems, getItemById} = useDirectusItems()
  const {generateMobileInstructionDto, logger} = useIntroductionUtils(globalSettings)

  const getMobileIntroduction = async (languageCode: string) => {
    return getInstructionEntity({
      query: {
        fields: [
          'id',
          'status',
          "icon.id",
          "icon.title",
          "icon.description",
          'translations.languages_code',
          'translations.title',
          'translations.description',
          "translations.images.*",
          "translations.images.directus_files_id.id",
          "translations.images.directus_files_id.title",
          "translations.images.directus_files_id.description"
        ],
        filter: {
          _and: [
            {
              status: {
                _eq: "published"
              }
            },
          ]
        }
      }
    }).then((data: any) => ({
      mobile_introductions: Array.isArray(data)
        ? data.map((item: any) => generateMobileInstructionDto(item, languageCode))
        : [],
    })).catch((e) => {
      logger.error('ERR getMobileIntroduction: ', e)
      return []
    })
  }

  const getInstructionEntity = (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<TypeIntroductionDto> | TypeIntroductionDto> => {
    let data: any = {
      collection: 'mobile_introduction',
      params: {
        fields: ['*'],
      }
    }

    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }

    if (options?.id) {
      data['id'] = options.id.toString()

      return getItemById(data)
    }
    return getItems(data)
  }
  return {
    getMobileIntroduction,
    logger
  }
}
