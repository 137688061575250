<template>
  <v-popup v-model:visible="openOTP" overlay closeOverlay keyPopup="popupOtp" :closeable="false">
    <template #header class="mb-7.5 relative">
            <span
                class="i-custom-close2 w-3 h-3 text-bw-24 absolute z-5 top-4.5 right-4.5 cursor-pointer"
                @click="closeOTP()"
            ></span>
      <h5 class="text-32px font-[550] leading-10 text-center text-black mb-3">
        <span> {{ $t('CONFIRM') }} </span>
        <span class="text-primary pl-2">OTP</span>
      </h5>
      <p v-if="data?.phone" class="text-center text-black/60 text-15px leading-6">
        {{ $t('WE_HAVE_SENT_THE_OTP_TO_THE_PHONE_NUMBER') }} {{ data?.phone }}
      </p>
    </template>
    <form @submit.prevent="handleSubmit()" class="mb-6.5 mt-7.5">
      <div class="w-full box-opt">
        <v-otp-input
            ref="otpInput"
            v-model:value="bindModal"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            input-type="letter-numeric"
            :conditionalClass="['one', 'two', 'three', 'four', 'five', 'six']"
            shouldautofocus
            @on-complete="handleOnComplete"
        />
        <p class="text-13px leading-6 text-bw-23 mt-6 text-center gap-1 flex justify-center">
          <span> {{ $t('THE_CODE_WILL_EXPIRE_AFTER') }} </span>
          <span class="text-primary">{{ time }}s</span>
        </p>
      </div>
    </form>
    <template #footer>
      <div class="w-full flex justify-center">
        <button
            class="relative md:w-max w-full btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm rounded-30px mt-auto py-4.5 px-6 flex items-center justify-center cursor-pointer outline-none min-w-43 min-h-14"
            :class="successSubmit ? '' : 'pointer-events-none'"
            @click="handleSubmit()"
        >
          <p v-if="successSubmit">{{ $t('CONFIRM_NOW') }}</p>
          <div v-else class="absolute loaderForm"></div>
        </button>
      </div>
    </template>
  </v-popup>

  <popup-result-success v-model:visible="isResult"></popup-result-success>
  <popup-result-fail
      v-model:visible="openPopupFail"
      :title="notificationError"
      :intro="introError"
  ></popup-result-fail>
</template>

<script setup lang="ts">
import VOtpInput from 'vue3-otp-input'
import {useReCaptcha} from 'vue-recaptcha-v3'
import PopupResultSuccess from './__PopupResultSuccess.vue'
import PopupResultFail from './__PopupResultFail.vue'
import nuxtStorage from 'nuxt-storage'

const {t} = useI18n()

const props = defineProps({
  data: {
    type: Object
  }
})

const openOTP = ref(true)
const successSubmit = ref(true)
const isResult = ref(false)
const notificationError = ref()
const introError = ref()
const openPopupFail = ref(false)

const time = ref(props.data?.time)

const emits = defineEmits(['signupSuccess', 'closeOTP'])
const OTP = ref()
const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null)
const bindModal = ref('')

const handleOnComplete = (value: string) => {
  OTP.value = value
}

const closeOTP = () => {
  openOTP.value = false
}

watch(
    () => openOTP.value,
    (newValue) => {
      if (!openPopupFail.value && !isResult.value) {
        emits('closeOTP')
      }
    }
)
watch(
    () => openPopupFail.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

watch(
    () => isResult.value,
    (newValue) => {
      if (!newValue) {
        emits('closeOTP')
      }
    }
)

const startOTP = () => {
  if (time.value > 0) {
    time.value--
    nuxtStorage.localStorage.setData('time_otp_signup_' + props.data?.phone, time.value, time.value, 's')
  } else {
    nuxtStorage.localStorage.removeItem('time_otp_signup_' + props.data?.phone)
    emits('closeOTP', false)
    notificationError.value = t('THE_OTP_CODE_HAS_EXPIRED')
    introError.value = t('PLEASE_RESUBMIT_YOUR_REQUEST_TO_GET_A_NEW_OTP')
  }
}
const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  // optional you can await for the reCaptcha load
  await recaptchaInstance?.recaptchaLoaded()

  // get the token, a custom action could be added as argument to the method
  const token = await recaptchaInstance?.executeRecaptcha('login')

  return token
}

const handleSubmit = async () => {
  if (OTP.value && OTP.value.length === 6) {
    successSubmit.value = false
    const token = await recaptcha()

    await $fetch('/api/verify/registration', {
      method: 'POST',
      params: {
        response: token
      },
      body: JSON.stringify({
        phoneNumber: props.data?.phone,
        fullName: props.data?.name,
        gender: props.data?.gender,
        otp: OTP.value
      })
    }).then((data) => {
      successSubmit.value = true
      if (data?.data?.data && data?.code == 201) {
        nuxtStorage.localStorage.removeItem('time_otp_signup_' + props.data?.phone)
        openOTP.value = false
        isResult.value = true
      } else if (data?.code == 500) {
        openOTP.value = false
        openPopupFail.value = true
        notificationError.value = t('THE_OTP_YOU_ENTERED_IS_NOT_CORRECT')
        introError.value = t('PLEASE_DOUBLE_CHECK_THE_RECEIVED_OTP')
      } else {
        openOTP.value = false
        openPopupFail.value = true
        notificationError.value = t('THERE_WAS_A_PROBLEM_MAKING_THE_REQUEST')
        introError.value = t('SORRY_WE_WILL_FIX_THE_PROBLEM_AS_SOON_AS_POSSIBLE_WISH_YOU_SYMPATHIZE')
      }
    })
  }
}

onMounted(() => {
  if (time.value > 0) {
    setInterval(() => {
      startOTP()
    }, 1000)
  }
})
</script>

<style></style>
