import {consola} from "consola";
import {useParseData} from "../../../utils/parse.utils";
import {Logger} from '../../../utils/logger.utils'

export const useIntroductionUtils = (globalSettings: any) => {

  const {generateDefaultFieldDirectus, getTransItemMulti, getTransMultiImage, getImageField} = useParseData();
  const route = useRoute();
  const defaultLanguage = globalSettings.languages.filter((ite: any) => ite.default === true)[0].code || "vi-VI";
  const language = route.params.langCode || defaultLanguage;
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);

  const generateMobileInstructionDto = (item: any, langCode: string = language): any => {

    try {
      const output = {
        ...generateDefaultFieldDirectus(item as any),
        icon: getImageField(item, "icon"),
        title: getTransItemMulti(item, langCode, "title"),
        description: getTransItemMulti(item, langCode, "description"),
        images: getTransMultiImage(item, langCode, "images")
      };

      delete output["translations"];
      return output;

    } catch (e) {
      consola.error("Error: generateMobileInstructionDto!");
      consola.error(e);
      return {};
    }
  }

  return {
    generateMobileInstructionDto,
    logger
  }
}
