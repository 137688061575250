<template>
  <section class="py-10 lg:py-20 container overflow-hidden">
    <GlobalHeading
        data-aos="fade-left"
        data-aos-once="true"
        title="Tính năng nổi bật"
        opt-key-title="mobileAppPage.titleFeature"
        class="mb-6 ml-1"
    ></GlobalHeading>
    <div class="flex flex-col gap-6 mb-10 lg:mb-20">
      <div
          class="w-full relative aspect-85/118 lg:aspect-310/160 cursor-pointer"
          @click="isLiveEditor ? '' : (isOpenVideo = !isOpenVideo)"
      >
        <div
            v-if="!isOpenVideo"
            :class="isLiveEditor && isOpenVideo ? 'pointer-events-auto' : 'pointer-events-none'"
            class="bg-black/40 absolute inset-0 flex justify-center items-center z-10 rounded-15px"
        >
          <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="200">
            <IconsPlayPrimary class="w-15 h-15"></IconsPlayPrimary>
          </div>
        </div>

        <div v-if="!isOpenVideo" class="h-full w-full">
          <v-image
              option_key="mobileAppPage.imageFeature"
              class="w-full h-full object-cover rounded-15px"
              :width="1400"
          >
          </v-image>
        </div>

        <iframe
            v-if="youtubeLinkRender && isOpenVideo"
            :src="youtubeLinkRender"
            frameborder="0"
            allowfullscreen
            allow="autoplay"
            class="w-full h-full"
        ></iframe>
      </div>
    </div>
    <div class="flex xl:flex-row flex-col gap-8">
      <div class="flex flex-col gap-8 w-full xl:max-w-152.5">
        <item-feature
            data-aos="fade-right"
            :data-aos-once="true"
            v-for="(item, index) in dataRender"
            :data="item"
            :key="item?.id"
            :index="index + 1"
            class="removeAnimationFeature"
            @click=";[(indexActive = index), removeAttribute()]"
            :active="indexActive == index ? true : false"
        >
        </item-feature>
      </div>
      <div
          data-aos="fade-left"
          data-aos-once="true"
          class="bg-final-02 rounded-15px overflow-hidden relative flex-1 justify-center items-center py-20 xl:flex hidden"
      >
        <div class="absolute inset-0 flex justify-center items-center mx-8.5">
          <img class="w-full object-contain" src="/images/circle/hinh20.png" alt="circle"/>
        </div>
        <div class="relative w-full" data-aos="zoom-in" data-aos-once="true" data-aos-delay="300">
          <!-- <pre>{{ dataRender }}</pre> -->
          <Swiper
              :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
              :space-between="0"
              :slidesPerView="1"
              :pagination="{
                            dynamicBullets: true,
                            clickable: true
                        }"
              :navigation="{
                            nextEl: '.next-swiper-feature-main',
                            prevEl: '.prev-swiper-feature-main'
                        }"
              :grabCursor="true"
              :loop="true"
          >
            <SwiperSlide
                v-for="(item, index) in dataRender[indexActive]?.images"
                :key="index"
                class="w-full !flex !justify-center mb-12"
            >
              <div class="w-75 aspect-75/150 rounded-15px">
                <img
                    :src="getThumbnail(item?.id, 800, true)"
                    :alt="item?.description || item?.title"
                    class="w-full h-full object-contain roudned-15px"
                />
              </div>
            </SwiperSlide>
          </Swiper>
          <!-- <div class="mt-12 slide-pagination-feature-main flex justify-center"></div> -->
          <GlobalNavigationSwiper
              v-show="dataRender[indexActive]?.images.length > 1"
              option
              nextEl="next-swiper-feature-main"
              prevEl="prev-swiper-feature-main"
          >
          </GlobalNavigationSwiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import ItemFeature from './__ItemFeature.vue'

const config = useRuntimeConfig().public

const indexActive = ref<number | any>(0)
const isOpenVideo = ref(false)
const isLiveEditor = inject('is_edit')

const extractYouTubeVideoID = (url) => {
  const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  return match && match[1] ? match[1] : null
}

const video_app_intro = inject('globalSettings')?.links[0]?.app_intro
const youtubeLink = ref(video_app_intro)
const videoID = extractYouTubeVideoID(youtubeLink.value)
const youtubeLinkRender = `https://www.youtube.com/embed/${videoID}?autoplay=1`

const {getMobileIntroduction} = useIntroduction()
const res: any = await getMobileIntroduction(useRoute().params.langCode)

const dataRender = computed(() => {
  return res.mobile_introductions
})

const removeAttribute = () => {
  document.querySelectorAll('.removeAnimationFeature').forEach((item) => {
    item.removeAttribute('data-aos')
  })
}
</script>

<style></style>
