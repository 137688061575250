<template>
  <div
      class="grid grid-cols-1 lg:grid-cols-2 gap-15 lg:gap-20 container pt-10 md:pt-20 pb-22 md:border-b border-bw-08 overflow-hidden"
  >
    <div class="flex flex-col gap-20">
      <div data-aos="fade-right" data-aos-once="true" class="mb-10 w-full">
        <v-text
            tag="p"
            class="text-sm font-semibold leading-6 text-bw-09/50 uppercase mb-3"
            option_key="mobileAppPage.subTitleOverview"
            default_value="MOBILE APP IZION24"
        ></v-text>
        <v-content
            class="font-[550] text-3xl lg:text-44px leading-5xl lg:leading-56px text-black w-full lg:w-125"
            :default_value="`Ứng dụng IZIon24 giải pháp bảo hiểm thông minh <p class='text-base text-black/60 leading-6 mt-5 font-normal'> Ứng dụng bảo hiểm IZIon24 sẽ đơn giản hoá mọi thông tin và mang đến sản phẩm phù hợp nhất với nhu cầu của riêng bạn. Hãy trải nghiệm việc mua bảo hiểm nhanh chóng chưa từng có trước đây </p>`"
            option_key="mobileAppPage.contentOverview"
        />
      </div>
      <div
          data-aos="fade-up"
          data-aos-once="true"
          class="hidden lg:flex md:flex-row flex-col gap-6 md:divide-x-1 md:divide-black/40"
      >
        <div class="flex flex-col gap-2.5">
          <v-text
              tag="p"
              option_key="subTitleBanerDownload1.global"
              class="text-black/60 font-semibold text-sm leading-6 uppercase"
          />
          <div class="space-y-2.5">
            <a
                :href="links_global?.appstore"
                @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_APP_STORE_KEY)"
                target="_blank"
                class="block w-37.5 aspect-38/12 overflow-hidden"
            >
              <img
                  src="/images/global/appstore.svg"
                  alt="appstore"
                  class="w-full h-full object-contain"
              />
            </a>
            <a
                :href="links_global?.google_play"
                @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY)"
                target="_blank"
                class="block w-37.5 aspect-38/12 overflow-hidden"
            >
              <img
                  src="/images/global/googlePlay.svg"
                  alt="googleplay"
                  class="w-full h-full object-contain"
              />
            </a>
          </div>
        </div>
        <div class="md:pl-6 space-y-2.5">
          <v-text
              tag="p"
              option_key="subTitleBanerDownload2.global"
              class="text-black/60 font-semibold text-sm leading-6 uppercase"
          />
          <div class="block">
            <div class="w-27 aspect-1/1 overflow-hidden">
              <img
                  :src="getThumbnail(links_global?.qr, 162, true)"
                  alt="qr"
                  class="w-full h-full object-contain rounded-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center gap-10 flex-1">
      <div class="relative w-full h-full flex items-end">
        <div data-aos="fade-left" data-aos-once="true" class="w-full lg:h-full aspect-135/116 image-contain">
          <v-image
              option_key="mobileAppPage.imageOverview"
              class="w-full h-full object-contain rounded-15px"
              :width="1000"
          >
          </v-image>
        </div>
        <img
            class="w-30 md:w-38 aspect-38/28 object-contain absolute left-1/2 bottom-0 z-3 animationSway"
            data-aos="zoom-in"
            data-aos-once="true"
            src="/images/global/masgotRight.png"
            alt="icon"
        />
      </div>
      <div data-aos="fade-up" data-aos-once="true" class="flex-col gap-6 flex lg:hidden">
        <div class="flex flex-col gap-2.5">
          <v-text
              tag="p"
              default_value="Tải trực tiếp từ:"
              option_key="subTitleBanerDownload1.global"
              class="text-black/60 font-semibold text-sm leading-6 uppercase"
          />
          <div class="space-y-2.5">
            <a
                :href="links_global?.appstore"
                @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_APP_STORE_KEY)"
                target="_blank"
                class="block w-37.5 aspect-38/12 overflow-hidden"
            >
              <img
                  src="/images/global/appstore.svg"
                  alt="appstore"
                  class="w-full h-full object-contain"
              />
            </a>
            <a
                :href="links_global?.google_play"
                @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY)"
                target="_blank"
                class="block w-37.5 aspect-38/12 overflow-hidden"
            >
              <img
                  src="/images/global/googlePlay.svg"
                  alt="googleplay"
                  class="w-full h-full object-contain"
              />
            </a>
          </div>
        </div>
        <div class="flex flex-col gap-2.5">
          <v-text
              tag="p"
              default_value="quét mã qr:"
              option_key="subTitleBanerDownload2.global"
              class="text-black/60 font-semibold text-sm leading-6 uppercase"
          />
          <div class="w-27 aspect-1/1">
            <img
                :src="getThumbnail(links_global?.qr, 162, true)"
                alt="qr"
                class="w-full h-full object-contain rounded-2"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DOWNLOAD_EVENT_ID,
  DOWNLOAD_APP_ON_APP_STORE_KEY,
  DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY,
  useEventTracking
} from '../../../composables/ackee/event'
const links_global = inject('globalSettings')?.links[0]
</script>

<style></style>
