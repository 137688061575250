<template>
  <section class="relative container overflow-hidden lg:border-t lg:border-b border-bw-08 py-10 lg:py-20">
    <div class="flex xl:flex-row flex-col gap-5 xl:gap-26">
      <div class="w-full xl:max-w-79" data-aos="fade-right" data-aos-once="true">
        <div class="mb-10 lg:mb-20">
          <v-content
              class="text-black font-[550] text-34px leading-47px"
              :default_value="`Đăng ký IZIon24 <p class='text-base leading-6 text-black/60 mt-3 font-normal'> Đội ngũ sáng lập giàu kinh nghiệm xây dựng và phát triển các giải pháp công nghệ, chuyển đổi số đặc biệt là lĩnh vực Insurtech. </p>`"
              option_key="mobileAppPage.contentSignUp"
          />
        </div>

        <div class="w-38 sm:w-48 aspect-38/32 lg:block hidden">
          <img
              src="/images/brand-iz4-3.png"
              alt="masgot"
              class="w-full h-full object-contain animationSway"
          />
        </div>
      </div>
      <form
          @submit.prevent="handleSubmit()"
          class="relative overflow-hidden px-5 xl:px-13.5 pt-8 xl:pt-12 pb-17.5 rounded-20px w-full xl:w-8/12 bg-white"
          style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
          data-aos="fade-left"
          data-aos-once="true"
          id="form-signUp"
      >
        <div
            class="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-3 lg:gap-y-6 md:pb-9 mb-6 md:mb-8.5 md:border-b border-black/7"
        >
          <div class="relative z-1">
            <label
                for="name_sign_up_mobile_app"
                :class="errorName ? '!text-red-500' : ''"
                class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
            >
              {{ $t('FIRST_AND_LAST_NAME') }}
            </label>
            <input
                id="name_sign_up_mobile_app"
                type="text"
                class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 border-non/80 font-mediume focus:ring-0 text-black placeholder-bw-12/50 capitalize h-13.5 flex items-center"
                :class="errorName ? '!placeholder:text-red-500/80 border-red-500' : ''"
                :placeholder="$t('ENTER_YOUR_FIRST_AND_LAST_NAME')"
                v-model="name"
                @blur="checkName()"
            />
            <Transition name="fade">
                            <span class="text-red-500 pl-5 mt-2" v-if="errorName">
                                {{ $t('PLEASE_ENTER_YOUR_NAME') }}
                            </span>
            </Transition>
          </div>
          <div class="relative z-5">
            <label class="text-bw-26 text-13px font-semibold block pb-2 uppercase">
              {{ t('GENDER') }}
            </label>
            <global-select :data="dataGender" v-model:senData="gender" class="rounded-full"></global-select>
          </div>

          <div class="relative z-1">
            <label
                for="phone_sign_up_mobile_app"
                :class="errorPhone ? '!text-red-500' : ''"
                class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
            >
              {{ t('PHONE_NUMBER') }}
            </label>
            <input
                id="phone_sign_up_mobile_app"
                type="text"
                class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50 h-13.5 flex items-center"
                :class="errorPhone ? '!placeholder:text-red-500/80 border-red-500' : ''"
                :placeholder="t('ENTER_YOUR_PHONE_NUMBER')"
                v-model="phone"
                @input="convertedPhoneNumber()"
                @blur="checkPhone()"
            />
            <Transition name="fade">
                            <span class="text-red-500 pl-5 mt-2" v-if="errorPhone">
                                {{ $t('INCORRECT_PHONE_NUMBER') }}
                            </span>
            </Transition>
          </div>
          <div class="relative z-1">
            <label
                for="email_sign_up_mobile_app"
                :class="errorEmail ? '!text-red-500' : ''"
                class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
            >
              Email
            </label>
            <input
                id="email_sign_up_mobile_app"
                type="email"
                class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50 h-13.5 flex items-center"
                :class="errorEmail ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''"
                :placeholder="t('ENTER_YOUR_EMAIL')"
                v-model="email"
                @blur="checkEmail()"
            />
            <Transition name="fade">
                            <span class="text-red-500 pl-5 mt-2" v-if="errorEmail">
                                {{ $t('PLEASE_ENTER_A_VALID_EMAIL') }}
                            </span>
            </Transition>
          </div>

          <!-- <div class="relative z-1">
                  <span
                      :class="errorPassword ? '!text-red-500' : ''"
                      class="text-bw-26 text-13px font-semibold block pb-2 uppercase"
                      for="full_name"
                  >
                      {{ t('PASSWORD') }}
                  </span>
                  <input
                      type="password"
                      class="border border-#CBCBCB font-medium outline-none w-full rounded-28px py-15px text-15px px-5.5 text-black/80 placeholder-bw-12/50"
                      :class="
                          errorPassword ? '!placeholder:text-red-500/80 border-red-500 !text-red-500' : ''
                      "
                      :placeholder="t('ENTER_YOUR_PASSWORD')"
                      v-model="password"
                      @blur="checkPassword()"
                  />
                  <Transition name="fade">
                      <span class="text-red-500 pl-5 mt-2" v-if="errorPassword">
                          {{ $t('PASSWORD_MUST_BE_AT_LEAST_8_CHARACTERS') }}
                      </span>
                  </Transition>
              </div> -->
        </div>
        <div class="flex lg:flex-row flex-col lg:justify-between justify-center items-center">
          <GlobalVerifyForm :error="errorChecked" v-model:checked="isChecked" class="lg:mt-0 mb-5"/>

          <button
              class="relative min-w-full lg:min-w-39 lg:w-max px-6 btn-submit-contact bg-accent-01 lg:bg-primary text-white font-semibold uppercase text-sm rounded-30px mt-auto py-4.5 flex items-center justify-center cursor-pointer h-13.5 flex items-center"
              :class="successSubmit ? '' : 'pointer-events-none'"
          >
            <p v-if="successSubmit">{{ t('REGISTER_NOW') }}</p>
            <div v-else class="absolute loaderForm"></div>
          </button>
        </div>
      </form>
    </div>
  </section>
  <popup-confirm-otp v-if="openPopupOTP" :data="dataSubmit" @closeOTP="openPopupOTP = false"></popup-confirm-otp>
  <popup-result-fail
      v-model:visible="openPopupFail"
      :title="notificationError"
      :intro="introError"
  ></popup-result-fail>
</template>

<script setup lang="ts">
import {useReCaptcha} from 'vue-recaptcha-v3'
import PopupConfirmOtp from './__PopupConfirmOpt.vue'
import PopupResultFail from './__PopupResultFail.vue'
import nuxtStorage from 'nuxt-storage'
import {
  REGISTER_ACCOUNT_IZION24_EVENT_ID,
  REGISTER_ACCOUNT_IZION24_KEY,
  useEventTracking
} from '../../../composables/ackee/event'

const {t} = useI18n()

const name = ref()
const errorName = ref(false)
const phone = ref()
const errorPhone = ref(false)
const email = ref()
const errorEmail = ref(false)
const password = ref()
const errorPassword = ref(false)
const gender = ref()
const successSubmit = ref(true)
const openPopupOTP = ref(false)
const dataSubmit = ref()
const errorChecked = ref(false)
const isChecked = ref()
const notificationError = ref()
const introError = ref()
const openPopupFail = ref(false)
const expiredOTP = ref(false)

const dataGender = [
  {
    id: 'M',
    content: '<p>' + t('MR') + '</p>'
  },
  {
    id: 'S',
    content: '<p>' + t('MS') + '</p>'
  }
]

const checkName = () => {
  if (name.value) {
    errorName.value = false
  } else {
    errorName.value = true
  }
}

const convertedPhoneNumber = () => {
  if (phone.value !== '' && phone.value[0] === '0') {
    var convertedPhoneNumber = phone.value?.substring(1)
    phone.value = '+84' + convertedPhoneNumber
  }
}
const checkPhone = () => {
  var vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
  if (phone.value) {
    var convertedPhoneNumber = phone.value.replace(/^\+84/, '0')
    if (vnf_regex.test(convertedPhoneNumber)) {
      errorPhone.value = false
    } else {
      errorPhone.value = true
    }
  } else {
    errorPhone.value = true
  }
}

const checkEmail = () => {
  let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  if (email.value) {
    if (filter.test(email.value)) {
      errorEmail.value = false
    } else {
      errorEmail.value = true
    }
  } else {
    errorEmail.value = false
  }
}
const checkChecked = () => {
  if (isChecked.value) {
    errorChecked.value = false
  } else {
    errorChecked.value = true
  }
}

watch(
    () => isChecked.value,
    () => {
      checkChecked()
    }
)

// const checkPassword = () => {
//     if (password.value.length > 8) {
//         errorPassword.value = false
//     } else {
//         errorPassword.value = true
//     }
// }

const calculateRemainingValue = (createdTimestamp: any, expiryValue: any, expiryUnit: any, value: any) => {
  const currentTime = Date.now()

  let expiryMilliseconds
  switch (expiryUnit) {
    case 's':
      expiryMilliseconds = expiryValue * 1000
      break
    case 'm':
      expiryMilliseconds = expiryValue * 60 * 1000
      break
    case 'h':
      expiryMilliseconds = expiryValue * 60 * 60 * 1000
      break
    case 'd':
      expiryMilliseconds = expiryValue * 24 * 60 * 60 * 1000
      break
      default_value: return value
  }

  const expirationTime = createdTimestamp + expiryMilliseconds

  if (currentTime >= expirationTime) {
    return 0
  } else {
    const remainingMilliseconds = expirationTime - currentTime
    const remainingRatio = remainingMilliseconds / expiryMilliseconds
    const remainingValue = value * remainingRatio
    return remainingValue
  }
}

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
  await recaptchaInstance?.recaptchaLoaded()
  const token = await recaptchaInstance?.executeRecaptcha('login')
  return token
}

const getOTP = async () => {
  const token = await recaptcha()
  successSubmit.value = false

  await $fetch(`/api/verify/otp`, {
    method: 'POST',
    params: {
      response: token,
      type: 0,
      phoneNumber: phone.value
    }
  }).then((response) => {
    successSubmit.value = true
    if (response.code == 201) {
      dataSubmit.value = {
        phone: phone.value,
        name: capitalizeFirstLetterOfWords(name.value),
        gender: gender.value,
        email: email.value,
        time: 90
      }
      openPopupOTP.value = true
    } else if (response.code == 429) {
      openPopupFail.value = true
      notificationError.value = t('AN_ERROR_OCCURRED_WHILE_SENDING_OTP_REQUEST')
      introError.value = t('LOOKS_LIKE_YOU_MOVING_TOO_FAST')
    } else if (response.code == 409) {
      openPopupFail.value = true
      notificationError.value = t('PHONE_NUMBER2') + ' ' + phone.value + ' ' + t('REGISTERED_IN_THE_SYSTEM')
      introError.value = t('PLEASE_USE_ANOTHER_PHONE_NUMBER_TO_REGISTER_AN_ACCOUNT')
    } else {
      openPopupFail.value = true
      notificationError.value = t('THE_SERVER_IS_HAVING_PROBLEMS')
      introError.value = t('SORRY_WE_ARE_EXPERIENCING_SOME_TECHNICAL')
    }
  })
}

const handleSubmit = async () => {
  checkName()
  checkPhone()
  checkEmail()
  checkChecked()
  if (!errorName.value && !errorPhone.value && !errorEmail.value && !errorChecked.value) {
    useEventTracking(REGISTER_ACCOUNT_IZION24_EVENT_ID, REGISTER_ACCOUNT_IZION24_KEY)

    const dataLocal = localStorage.getItem('time_otp_signup_' + phone.value)
    const storedData = JSON.parse(dataLocal)

    if (storedData) {
      const {created, expiry, unit, value} = storedData
      const remainingValue = calculateRemainingValue(created, expiry, unit, value)
      if (remainingValue != 0) {
        dataSubmit.value = {
          phone: phone.value,
          name: name.value,
          gender: gender.value,
          email: email.value,
          time: Math.floor(remainingValue)
        }
        openPopupOTP.value = true
      } else {
        nuxtStorage.localStorage.removeItem('time_otp_signup_' + phone.value)
        getOTP()
      }
    } else {
      getOTP()
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
