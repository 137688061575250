<template>
  <main>
    <section-overview></section-overview>
    <section-feature></section-feature>
    <section-featured-customer></section-featured-customer>
    <section-sign-up></section-sign-up>
    <section-faqs></section-faqs>
    <section-download></section-download>
  </main>
</template>

<script setup lang="ts">
import SectionOverview from './components/SectionOverview.vue'
import SectionFeature from './components/SectionFeature.vue'
import SectionFeaturedCustomer from './components/SectionFeaturedCustomer.vue'
import SectionSignUp from './components/SectionSignUp.vue'
import SectionFaqs from './components/SectionFaqs.vue'
import SectionDownload from './components/SectionDownload.vue'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)
const lang_switch = generateLangSwitch('mobile_app', useRoute().params.langCode)
useDirectusCollectionSeo('mobile_app_index', {}, useRoute().params.langCode)
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = lang_switch
})
</script>

<style></style>
