<template>
  <section class="flex flex-col w-full">
    <div class="w-full relative overflow-hidden">
      <div
          class="absolute inset-0"
          style="
                    background: radial-gradient(
                        162.55% 98.99% at 73.6% 64.69%,
                        #ffa17d 5.03%,
                        #ff8050 42.75%,
                        #ed5a22 100%
                    );
                "
      ></div>
      <div data-aos="zoom-in" data-aos-once="true" class="relative container">
        <div class="absolute -top-[10%] -left-[15%] w-219 aspect-1/1 lg:block hidden">
          <img src="/images/circle/hinh7.svg" alt="cricle" class="w-full h-full object-contain"/>
        </div>
        <div class="absolute bottom-0 -right-[12%] w-123 aspect-1/1 sm:hidden block">
          <img src="/images/circle/hinh8.svg" alt="cricle" class="w-full h-full object-contain"/>
        </div>
        <Swiper
            :autoplay="{
                        delay: 10000,
                        disableOnInteraction: false
                    }"
            :effect="'fade'"
            :fadeEffect="{ crossFade: true }"
            :modules="[SwiperAutoplay, SwiperThumbs, SwiperEffectCreative, SwiperEffectFade]"
            :space-between="100"
            :slidesPerView="1"
            :grabCursor="true"
            :thumbs="{ swiper: thumbsSwiper }"
            class="relative z-10 percentage-box"
        >
          <SwiperSlide v-for="(item, index) in dataRender" :key="index" class="!h-auto">
            <Customer :data="item"></Customer>
          </SwiperSlide>
        </Swiper>
        <div
            class="absolute lg:top-auto lg:bottom-10 lg:right-18 xl:right-[20%] z-15 w-full max-w-80 md:max-w-100 overflow-x-auto"
            id="swiper_thumbs_feedback_customer"
            :class="`bottom-${positionMobileSwiper}%`"
        >
          <Swiper
              @swiper="setThumbsSwiper"
              :slidesPerView="7"
              :freeMode="true"
              :watchSlidesProgress="true"
              :modules="[SwiperFreeMode, SwiperThumbs]"
          >
            <SwiperSlide v-for="(item, index) in dataRender" :key="index" class="cursor-pointer group mr-2">
              <div
                  class="w-10 h-10 rounded-full flex flex-col items-center justify-center !overflow-hidden bg-accent-01"
              >
                <img
                    v-if="item?.image"
                    :src="getThumbnail(item?.image?.id, 100, true)"
                    :alt="item?.image?.description || item?.image?.title"
                    class="w-full h-full object-cover transform group-hover:scale-110 duration-400 rounded-full"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
    <div
        class="flex justify-between py-10 lg:py-15 container lg:gap-5 xl:items-center xl:flex-row flex-col overflow-hidden"
    >
      <v-text
          data-aos="fade-right"
          data-aos-once="true"
          class="text-bw-09/50 uppercase text-sm leading-6 font-semibold border-b border-bw-08 pb-5 lg:border-none"
          option_key="mobileAppPage.subTitleFeaturedCustomer"
          default_value="ĐÁNH GIÁ TỪ APP STORE"
      ></v-text>

      <div
          data-aos="fade-left"
          data-aos-once="true"
          class="flex flex-wrap lg:justify-start justify-between divide-y lg:divide-y-0 w-full lg:w-max lg:gap-7 lg:divide-x-1 divide-bw-08"
      >
        <div class="gap-4 flex items-center py-6 lg:py-0 w-full lg:w-max">
          <v-text
              class="text-44px leading-48px font-semibold text-black"
              option_key="mobileAppPage.FeaturedCustomer1"
              default_value="4.8"
          ></v-text>
          <div>
            <v-content
                class="text-15px leading-18px font-semibold text-black/60 lg:w-34"
                option_key="mobileAppPage.textFeaturedCustomer1-1"
                default_value="Hơn 8 năm trong lĩnh vực"
            ></v-content>
          </div>
        </div>
        <div class="gap-4 flex items-center lg:pl-7 py-6 lg:py-0 w-full lg:w-max">
          <v-text
              class="text-44px leading-48px font-semibold text-black"
              option_key="mobileAppPage.FeaturedCustomer2"
              default_value="10K"
          ></v-text>
          <div>
            <v-content
                class="text-15px leading-18px font-semibold text-black/60 lg:w-34"
                option_key="mobileAppPage.textFeaturedCustomer2-1"
                default_value="Hơn 8 năm trong lĩnh vực"
            ></v-content>
          </div>
        </div>
        <div class="gap-4 flex items-center lg:pl-7 py-6 lg:py-0 w-full lg:w-max">
          <v-text
              class="text-44px leading-48px font-semibold text-black"
              option_key="mobileAppPage.FeaturedCustomer3 "
              default_value="1.17K"
          ></v-text>
          <div>
            <v-content
                class="text-15px leading-18px font-semibold text-black/60 lg:w-34"
                option_key="mobileAppPage.textFeaturedCustomer3-1"
                default_value="Hơn 8 năm trong lĩnh vực"
            ></v-content>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Customer from './__ItemCustomer.vue'

const {getFeedbacksInMobileApp} = useFeedBacks()
let thumbsSwiper = ref(null)
const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper
}

const dataRender = await getFeedbacksInMobileApp(useRoute().params.langCode).then((response) => response)

const positionMobileSwiper = ref()
const sizeScreen = ref()

const percentage = () => {
  const percentageBox = document.querySelector('.percentage-box')
  const percentageChild = document.querySelectorAll('.percentage-child')
  const swiperThumb = document.getElementById('swiper_thumbs_feedback_customer')
  let heightPercentageBox = percentageBox?.offsetHeight
  let heightPercentageChild = 0
  percentageChild.forEach((child) => {
    heightPercentageChild = child?.offsetHeight
  })
  positionMobileSwiper.value = Math.ceil((heightPercentageChild / heightPercentageBox) * 100) - 2
  if (swiperThumb && window.innerWidth < 1024) {
    swiperThumb.style.top = positionMobileSwiper.value + '%'
  } else {
    swiperThumb.style.top = 'auto'
  }
}
watch(
    () => sizeScreen.value,
    (newValue) => {
      percentage()
    }
)
onMounted(() => {
  sizeScreen.value = window.innerWidth
  window.addEventListener('resize', () => {
    sizeScreen.value = window.innerWidth
  })
  percentage()
})
</script>

<style>
.swiper-slide-thumb-active {
  @apply relative overflow-hidden;
}

.swiper-slide-thumb-active::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  background: linear-gradient(97.99deg, #fe210c 12.1%, #febd29 84.58%) border-box; /*3*/
  -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
</style>
