<template>
  <section class="py-10 lg:py-20 container relative z-10 overflow-hidden" v-if="intro_faqs && intro_faqs.length > 0">
    <div class="mb-12 flex justify-between gap-10">
      <GlobalHeading
          data-aos="fade-right"
          data-aos-once="true"
          :title="'FAQs'"
          class="flex-1"
          opt-key-title="mobileAppPage.titleFAQs"
      ></GlobalHeading>
      <div data-aos="fade-left" data-aos-once="true">
        <nuxt-link
            :to="t('FAQ_INDEX_URL')"
            class="text-accent-01 text-sm leading-5 uppercase font-semibold hover:text-primary duration-200 hidden lg:flex items-end"
        >
          <span> {{ t('SHOW_MORE') }}</span>
          <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
        </nuxt-link>
      </div>
    </div>
    <!-- <div class="flex justify-between hover:text-opacity-80 text-accent-01 mb-7">
        <GlobalHeading :title="'FAQs'" opt-key-title="mobileAppPage.titleFAQs"></GlobalHeading>
        <nuxt-link
            :to="t('FAQ_INDEX_URL')"
            class="uppercase text-sm font-semibold leading-5 flex items-center gap-2 h-full"
        >
            <span>{{ t('VIEW_ALL') }}</span>
            <span class="i-ic:baseline-arrow-forward-ios text-xs transform duration-200"></span>
        </nuxt-link>
    </div> -->
    <div class="flex flex-col">
      <div data-aos="fade-right" data-aos-delay="200" data-aos-once="true"></div>
      <div class="flex lg:flex-row flex-col gap-8">
        <div data-aos="fade-left" data-aos-delay="300" data-aos-once="true" class="w-full lg:w-1/2">
          <img
              :src="getThumbnail(intro_image?.id, 800, true)"
              :alt="intro_image?.description || intro_image?.id"
              class="w-full aspect-2/1 lg:aspect-1/1 rounded-15px object-cover"
          />
        </div>
        <div
            class="gap-5 accordion flex flex-col lg:w-1/2 w-full h-full"
            :class="intro_faqs && intro_faqs > 0 ? '' : 'justify-center'"
        >
          <GlobalBoxFAQs
              data-aos="fade-up"
              data-aos-once="true"
              @click="removeAttribute"
              class="removeAnimationFeature"
              v-for="(item, index) in intro_faqs"
              :index="index + 1"
              :key="item?.id"
              :data="item"
          ></GlobalBoxFAQs>
          <!-- <p v-else class="text-lg text-center text-bw-11 font-[550]">
              {{ $t('THE_CONTENT_IS_BEING_UPDATED') }}
          </p> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const {t} = useI18n()
const {getFaqSettings} = useAppGlobalData()
const dataRender = await getFaqSettings(useRoute().params.langCode)
const intro_faqs = dataRender?.intro_faqs
const intro_image = dataRender?.intro_image

const removeAttribute = () => {
  document.querySelectorAll('.removeAnimationFeature').forEach((item) => {
    item.removeAttribute('data-aos')
  })
}
</script>

<style>
.remove-mb > div {
  @apply !mb-0;
}
</style>
