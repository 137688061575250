<template>
  <div class="rounded-15px bg-feature relative cursor-pointer" v-if="data" :class="active ? 'active' : ''">
    <div class="absolute z-1 -bottom-10 -right-10 w-75 aspect-1/1 hidden xl:block" v-if="active">
      <img class="w-full h-full object-contain" src="/images/circle/hinh17.png" alt="circle"/>
    </div>
    <div
        class="relative z-10 sm:px-12.5 sm:py-10 p-8.5 flex justify-between items-center gap-10"
        :class="active ? 'xl:flex-row flex-col' : ''"
    >
      <div class="flex flex-col gap-4 flex-1 xl:max-w-78">
        <p
            class="leading-7 font-[bold] sm:text-xl text-base"
            :class="active ? '!text-white text-lg sm:text-23px animationActive' : ''"
        >
          {{ data?.title }}
        </p>
        <Transition name="slide-fade">
          <p class="text-sm md:text-base leading-6 text-white/90 xl:mb-19.5 min-h-20" v-if="active">
            {{ data?.description }}
          </p>
        </Transition>
        <p class="text-sm leading-6 font-[550] text-bw-01/50 xl:block hidden" v-if="active">0{{ index }}</p>
      </div>
      <!-- {{ getThumbnail(data?.icon + 'icon', 400) }} -->

      <div
          :class="
                    active
                        ? 'w-30 aspect-1/1 absolute bottom-5 right-5 xl:block hidden animationActive'
                        : 'md:w-21 md:h-21 w-15 h-15'
                "
      >
        <img
            v-if="data?.icon"
            :src="getThumbnail(data?.icon?.id, 400, true)"
            :alt="data?.icon?.description || data?.icon?.title"
            class="w-full h-full object-contain"
        />
      </div>

      <div class="relative w-full mt-7.5" :class="active ? 'xl:hidden block' : '!hidden'">
        <Swiper
            :modules="[SwiperAutoplay, SwiperPagination, SwiperNavigation]"
            :space-between="0"
            :slidesPerView="1"
            :pagination="{
                        dynamicBullets: true,
                        clickable: true
                    }"
            :navigation="{
                        nextEl: '.' + nameNextSwiper,
                        prevEl: '.' + namePrevSwiper
                    }"
            :grabCursor="true"
            :loop="true"
            class="swiper-pagination-custom"
        >
          <SwiperSlide
              v-for="(item, index) in data?.images"
              :key="index"
              class="w-full !flex justify-center mb-12"
          >
            <div class="w-40 md:w-70 aspect-75/150 rounded-15px">
              <img
                  :src="getThumbnail(item?.id, 800, true)"
                  loading="lazy"
                  :alt="item?.description || item?.title"
                  class="w-full h-full object-contain roudned-15px"
              />
            </div>
          </SwiperSlide>
        </Swiper>
        <!-- <div class="mt-12 flex justify-center" :class="namePagination"></div> -->

        <GlobalNavigationSwiper
            v-if="data?.images.length > 1"
            :nextEl="nameNextSwiper"
            :prevEl="namePrevSwiper"
        >
        </GlobalNavigationSwiper>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object
  },
  active: {
    type: Boolean
  },
  index: {
    type: Number
  }
})
// const namePagination = computed(() => {
//     return 'slide-pagination-feature' + props.index
// })

const nameNextSwiper = computed(() => {
  return 'next-swiper-feature' + props.index
})

const namePrevSwiper = computed(() => {
  return 'prev-swiper-feature' + props.index
})
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  display: none;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.animationActive {
  animation: animationActive 0.25s linear;
}

@keyframes animationActive {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
